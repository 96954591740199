import { navigate } from "gatsby";
import { useShouldRedirectWhenAuthenticated } from "../hooks/common.hooks";

export const AuthenticatedRedirect = ({ redirectTo = "/", children }) => {
  const shouldRedirect = useShouldRedirectWhenAuthenticated();

  if (shouldRedirect) {
    navigate(redirectTo);
  }

  return children;
};
