import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { STAFF_ROLE, USER_ROLE } from "../../commons/enum";
import { useIsTablet } from "../../hooks/common.hooks";
import { useStaffInfo } from "../../hooks/staff.hooks";
import { useMyInfo } from "../../hooks/user.hooks";
import { Account, Dashboard, Globe, Property, Staff } from "../../svgs";
import { logout } from "../../utils/auth";
import DesktopSideBar from "./components/desktop-sidebar";
import MobileSideBar from "./components/mobile-menu";
import "./side-bar.scss";

const defaultSideBarMenuItem = [
  {
    icon: <Account />,
    title: "Account",
    href: "account",
  },
];

const generateSidebarMenu = (userRole, isCompanyAdminRole = false) => {
  let sideBar = [...defaultSideBarMenuItem];

  if (userRole === USER_ROLE.ADMIN) {
    sideBar = [
      {
        icon: <Property />,
        title: "Company",
        href: "company",
      },
      ...sideBar,
    ];
  }
  if (userRole === USER_ROLE.ADMIN || isCompanyAdminRole) {
    sideBar = [
      {
        icon: <Staff />,
        title: "Staff",
        href: "staff",
      },
      {
        icon: <Globe />,
        title: "Web Pages",
        href: "web-pages",
      },
      ...sideBar,
    ];
  }
  if (userRole !== USER_ROLE.USER) {
    sideBar = [
      {
        icon: <Dashboard />,
        title: "Contact",
        href: "contact",
      },
      ...sideBar,
    ];
  }
  return sideBar;
};

const SideBar = () => {
  const data = useStaticQuery(graphql`
    query {
      gitCommit(latest: { eq: true }) {
        hash
        date
      }
      gitTag(latest: { eq: true }) {
        name
      }
    }
  `);
  const { gitTag, gitCommit: { date: gitDate = "" } = {} } = data;
  const gitTagName = gitTag?.name || "";

  const { data: userInfo } = useMyInfo();
  const { data: staffInfo } = useStaffInfo();
  const isCompanyAdminRole = useMemo(() => {
    return staffInfo?.staffRoles.some(
      (role) => role.role === STAFF_ROLE.COMPANY_ADMIN
    );
  }, [staffInfo]);
  const { firstName = "Anonymous", lastName = "", userRole } = userInfo || {};
  const isTablet = useIsTablet();
  const [logoutExpanded, setLogoutExpanded] = useState(false);
  const fullName = `${firstName} ${lastName}`;
  const sideBarMenuItem = useMemo(() => {
    return generateSidebarMenu(userRole, isCompanyAdminRole);
  }, [isCompanyAdminRole, userRole]);

  useEffect(() => {
    setLogoutExpanded(false);
  }, [isTablet]);

  const handleLogoutExpanded = () => {
    setLogoutExpanded(!logoutExpanded);
  };
  const handleLogout = () => {
    logout();
  };

  return (
    <>
      {isTablet ? (
        <MobileSideBar
          sideBarMenuItem={sideBarMenuItem}
          name={fullName}
          handleLogout={handleLogout}
          isExpanded={logoutExpanded}
          handleIsExpanded={handleLogoutExpanded}
          gitVersion={{ gitTagName, gitDate }}
        />
      ) : (
        <DesktopSideBar
          name={fullName}
          sideBarMenuItem={sideBarMenuItem}
          gitVersion={{ gitTagName, gitDate }}
        />
      )}
    </>
  );
};

export default React.memo(SideBar);
