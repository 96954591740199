import * as React from "react";

function SvgComponent() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0022 24.1429C19.1563 24.1429 24.1451 19.154 24.1451 13C24.1451 6.84597 19.1563 1.85715 13.0022 1.85715C6.8482 1.85715 1.85938 6.84597 1.85938 13C1.85938 19.154 6.8482 24.1429 13.0022 24.1429Z"
        fill="#33FF99"
      />
      <path
        d="M13.0039 25C19.6313 25 25.0039 19.6274 25.0039 13C25.0039 6.37258 19.6313 1 13.0039 1C6.37649 1 1.00391 6.37258 1.00391 13C1.00391 19.6274 6.37649 25 13.0039 25Z"
        stroke="#33FF99"
        stroke-width="1.5"
      />
      <path
        d="M7.25 14.3105L12.374 18.2836L18.7515 7.71567"
        stroke="#051315"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
